:root {
	--jk-blue: #6a46d4;
	--indigo: #6610f2;
	--purple: #6f42c1;
	--pink: #e83e8c;
	--red: #dc3545;
	--orange: #fd7e14;
	--yellow: #ffc107;
	--green: #28a745;
	--teal: #20c997;
	--cyan: #17a2b8;
	--white: #fff;
	--gray: #6c757d;
	--gray-dark: #343a40;
	--primary: #7078e8;
	--secondary: #6c757d;
	--success: #28a745;
	--info: #17a2b8;
	--warning: #ffc107;
	--danger: #dc3545;
	--light: #f8f9fa;
	--dark: #343a40;
	--breakpoint-xs: 0;
	--breakpoint-sm: 576px;
	--breakpoint-md: 768px;
	--breakpoint-lg: 992px;
	--breakpoint-xl: 1200px;
}

/*Body*/
$body-bg: #f4f3f9;
$box-white: #ffffff;

/*Theme Colors*/
$primary: transparent linear-gradient(102deg, #0f854e 0%, #3fd17e 100%) 0% 0%
	no-repeat padding-box;
$secondary: transparent linear-gradient(122deg, #b845ba 0%, #994fe3 100%) 0% 0%
	no-repeat padding-box;
$tertiary: #071d32;
$primary-hover: 'darken($primaryColor, 10%)';
$determined: transparent linear-gradient(96deg, #d285d5 0%, #b56cfe 100%) 0% 0%
	no-repeat;
$header-bg: transparent linear-gradient(180deg, #4d3886 0%, #7d5fce 100%) 0% 0%
	no-repeat padding-box;
$header-text: #ffffff;
$linkcolor: #1b84c7;
$outline-danger: #fda485;
$outline-danger-text: #bd522e;
$jk-bluegradient: #6a46d4;
$gray-txt: #616161;
$border-purple: #b45ed2;
$txt-purple: #994fe3;
$txt-green: #30b442;
$txt-org: #ffa5a5;
$txt-dark-purple: #4f198b;
$bg-pink: transparent linear-gradient(122deg, #fe23a0 0%, #ff2e6d 100%) 0% 0%;
$missed: #797575;
$completed: transparent linear-gradient(102deg, #17aa0d 0%, #28d41c 100%) 0% 0%
	no-repeat padding-box;
$inprogress: transparent linear-gradient(102deg, #df9e07 0%, #f8b415 100%) 0% 0%
	no-repeat padding-box;
$missed-bg: transparent linear-gradient(102deg, #c5c0c0 0%, #f3f0f0 100%) 0% 0%
	no-repeat padding-box;
$purple-light: #b6aaff;
$reading-card-color: #008dcf;
$skill-builder-card-color: #f36c12;

/*Improvements-start*/
$improvements-start: linear-gradient(
	146deg,
	rgba(253, 171, 128, 1) 0%,
	rgba(246, 116, 160, 1) 60%
);
$improvements-start-status: #8d9bb1;
$improvements-start-count: #f6769f;

$improvements-mid: linear-gradient(
	146deg,
	rgba(210, 133, 213, 1) 0%,
	rgba(181, 108, 254, 1) 60%
);
$improvements-mid-status: #8d9bb1;
$improvements-mid-count: #b86ffa;

$improvements-end: linear-gradient(
	146deg,
	rgba(121, 210, 255, 1) 0%,
	rgba(110, 143, 255, 1) 60%
);
$improvements-end-status: #8d9bb1;
$improvements-end-count: #6e90ff;
$background-color_1: #ebebeb;

//background: -webkit-linear-gradient(#eee, #333);

$sidebar: #161920;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
$lft: left;
$rgt: right;
$textcolor: #595959;
$textcolor-alt: #054154;
$tabactivecolor: #045184;
$descriptionBg: #132a41;
$cta-color: #50a5db;
$left-panel-header: #2f5565;
$logo-bg: #ffffff;
$white: #ffffff;
$ackBg: #faa61a;
$encBg: #0c71b9;

/*Color system*/

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #4f5467;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$blue: #03a9f3;
$indigo: #6610f2;
$purple: #ab8ce4;
$pink: #e83e8c;
$red: #e46a76;
$orange: #fb9678;
$yellow: #fec107;
$green: #00c292;
$teal: #20c997;
$cyan: #01c0c8;
$text-muted: '$gray-600';
$blue-3: #4e3a88;
$purple-1: #de91ff;
$green-1: #28d41c;
$gray-md-lgt: #dcdcdc;
$primary-btn: #456790;
$gray-lgt-2: #b7b7b7;
$gray-md: #757575;
$gray-md-lgt-1: #6e6e6e;
$gray-lgt: #a1a1a1;
$purple-2: #b13fb9;

/*Nav Tab*/

$nav-tab-color: #595959;
$nav-tab-active: #664cb4;
$nav-tab-border: #ffffff;
$nav-tab-active-filter: invert(50%) sepia(100%) saturate(6749%)
	hue-rotate(228deg) brightness(50%) contrast(100%);

/*Rating Stars*/
$blue-linear: linear-gradient(to right, #4e3a88 50%, #6a46d4 100%);
$green-linear: linear-gradient(to right, #6ffcac 20%, #3ce986 100%);
$org-linear: linear-gradient(to right, #fdab80 50%, #f674a0 100%);
$grey-linear: linear-gradient(to right, #dedede 50%, #cbcbcb 100%);
$text-linear: linear-gradient(to right, #4e3a88 0%, #6a46d4 100%);
$purple-linear: linear-gradient(to right, #994fe3 50%, #b13fb9 100%);
$purple-linear-light: linear-gradient(to right, #d820a2 50%, #940986 100%);

/*Onboarding Cards buttons and progress bars*/
$onboarding-gradient: radial-gradient(
	102.45% 459.84% at 0% 0%,
	#0097b2 0%,
	#0a9cab 10%,
	#20a89b 25%,
	#38b489 44%,
	#50c178 63%,
	#6ed162 87%,
	#7ed957 100%
);
$onboarding-card-color: #f4f6ff;
